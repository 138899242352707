export const IntroductionText = () => {
    const lines = [
        'It all began one quiet evening as I was casually scrolling through Instagram, indulging in the fleeting moments of leisure that the app offers. Among the endless stream of videos, one particular clip caught my eye: Nayeon, with her trademark bunny ears hat, dancing to "What is Love." There was something irresistibly charming about her performance, and as Instagram’s algorithm would have it, I kept stumbling upon that video again and again. Her playful energy drew me in, and before I knew it, I found myself humming along to the tune',
        'Not long after, while dining with a friend in the bustling heart of K-Town in New York, the familiar melody reached my ears, this time in real life. The song was playing in the background of the restaurant, and I instinctively reached for my phone to Shazam it. That simple act was the tipping point—my curiosity piqued, I dove headfirst into the world of TWICE, with a particular obsession for the infectious joy of "What is Love." As someone who has always had a fanboy streak, especially for singers, this newfound interest began to fill a void I hadn’t even realized was there. Their music and videos became a constant companion during my mundane, often lonely, corporate workdays, infusing them with a sense of purpose and joy that had been missing for a long time',
        'As the days passed, I learned that TWICE was scheduled to perform in Newark, NJ, in July 2019. I wrestled with the idea of attending, torn between my growing desire to see them live and the practical concerns of whether it was worth the investment. My indecision lasted right up until the day of the concert when, in a moment of impulsive excitement, I found a ticket in the first row of the non-floor section—a seat that promised an excellent view of the stage. The anticipation was electric, and when I finally walked into the concert, I was overwhelmed with emotion. Being there, surrounded by fellow fans and seeing TWICE up close, filled me with a happiness I hadn’t felt in years. The energy of the crowd, the interaction with the performers, and the sheer spectacle of it all made me vow that next time, I would secure a floor seat',
        'But "next time" turned out to be much longer than I anticipated. The global pandemic put a halt to concerts in 2020, and like so many others, I turned to content online to find solace during those isolating months. When I learned that TWICE would be performing on Long Island in February 2022, I wasted no time—this time, securing front-row seats. The excitement I felt in the lead-up to the concert was almost unbearable, a constant rush of anticipation that fueled me every day',
        'The concert itself was a surreal experience. Being only a few feet away from my favorite performers, who had previously only existed in videos on my screen, felt like a dream come true. The highlight of the night was when Tzuyu waved at me, and Nayeon surprised the crowd with an unexpected solo dance break during "Likey." I captured these moments on video, my voice echoing with pure, unfiltered joy as I cheered them on. It was a moment of bliss, and I knew then that this was more than just a hobby—it was becoming a significant part of my life',
        'Back home, I posted my videos, including clips of me shouting in sheer ecstasy, and to my surprise, they went viral. Suddenly, I found myself with a following of thousands of TWICE fans on social media, all drawn to the raw emotion I had captured on camera. When TWICE announced an encore performance in Los Angeles in May 2022, I decided to take the plunge and travel, just as some of my concert companions had suggested. Though I didn’t get front-row seats, I managed to snag spots close to the stage, offering excellent side views. The experience was just as exhilarating, and I felt a renewed sense of purpose as I shared more videos with my growing fanbase',
        'Over time, my life began to revolve around posting short-form concert videos, interacting with fellow fans online, and planning my next TWICE adventure. Their music continued to be a source of joy, and when they announced their next world tour, I was ready. I attended multiple shows across North America—New Jersey, Los Angeles, the Bay Area, Dallas, and even Toronto, where I joined forces with new friends I had made at previous concerts. I wanted to do something different this time, so I created signs to catch the members\' attention. The effort paid off when Nayeon, Tzuyu, and Dahyun all reacted to my signs, and once again, these moments went viral, solidifying my place in the TWICE fan community',
        'As my journey continued, I ventured beyond North America to see TWICE in London and the Philippines. My fiancée joined me for the latter, and though our seats weren’t as close, the experience was no less magical. She took videos of me dancing during the concert, and once again, those clips resonated with fans around the world',
        'The culmination of my TWICE adventure came in March 2024 when they announced one final concert in Las Vegas. This time, it would be special—our honeymoon. We spent over a week in Las Vegas, with the concert marking the grand finale of our trip. I scored front-row tickets once more and crafted a sign that read, "Just Married! Honeymoon selfie please?" The night was a blur of joy and excitement. Sana noticed our sign and graciously took selfies with us, while Tzuyu followed, blowing kisses into the camera, with Dahyun photobombing in the background. It was the perfect ending to a journey that had begun so unexpectedly with a simple Instagram video',
        'Reflecting on it all, I realize how much TWICE has brought into my life—not just music, but a sense of community, friendship, and unforgettable memories. From discovering them on Instagram to sharing a once-in-a-lifetime moment with them in Las Vegas, TWICE has become a cornerstone of my happiness, a beacon of joy in both the ordinary and extraordinary moments of life.'
    ];

    return (
        <div>
            {lines.map((line, index) => {
                const isEven = index % 2 === 0;
                return (
                    <div 
                        style={{
                            margin: '30px 0px',
                            textAlign: isEven ? 'left' : 'right',
                            color: isEven ? '#DDDDDD' : '#AAAAAA'
                        }}
                    >
                        {line}
                    </div>
                );
            })}
        </div>
    );
};