import React from 'react';
import './App.css';
import { IntroductionText } from './IntroductionText';
import { InstagramEmbed } from 'react-social-media-embed';

function App() {
  return (
    <div className="App">
      <div className={'name-header'}>{'Patrick Abejar\'s Music Fan Experience'}</div>
      <div style={{display: 'flex'}}>
        <div style={{ justifyContent: 'center' }}>
          <InstagramEmbed url="https://www.instagram.com/p/C6uru86r-4T/" width={328} />
        </div>
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <IntroductionText/>
        </div>
      </div>
    </div>
  );
}

export default App;
